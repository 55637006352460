// normalize CSS across browsers
import './static/styles/normalize.css';
// custom CSS styles
import './static/styles/layout.css';
import { ShopifyProvider, CartProvider } from '@shopify/hydrogen-react';
import { AnimatePresence } from 'framer-motion';
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const React = require(`react`);

export const wrapPageElement = ({ element }) => {
  return (
    <ShopifyProvider
      storeDomain={`ernest-performance1.myshopify.com`}
      storefrontToken={
        process.env.GATSBY_SHOPIFY_PUBLIC_STOREFRONT_API_TOKEN || ``
      }
      storefrontApiVersion={`2024-07`}
      countryIsoCode="AU"
      languageIsoCode="EN"
    >
      <CartProvider>
        <AnimatePresence mode="wait">{element}</AnimatePresence>
      </CartProvider>
    </ShopifyProvider>
  );
};
